import { receiveFecha, receiveFechaFormat2 } from "../../../../../adapters/helpers"

const PersonasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    persona: (`${item.nombre || ''} ${item.apellido1 || ''} ${item.apellido2 || ''}`) || '',
                    numeroasociado: item.numeroasociado || '',
                    dni: item.nif || '',
                    fechabaja: receiveFechaFormat2(receiveFecha(item.fechabajapersona)),
                    fechaalta: receiveFechaFormat2(receiveFecha(item.fechaaltapersona)),
                    fechastatuspersona: receiveFecha(item.fechastatuspersona),
                    statuspersona: item.statuspersona || '',
                    fechaestado: item.fechabajapersona ? receiveFechaFormat2(receiveFecha(item.fechabajapersona)) : receiveFechaFormat2(receiveFecha(item.fechaaltaasociado || item.fechaaltapersona)),
                    nombre: item.nombre || '',
                    apellidos: `${item.apellido1 || ''} ${item.apellido2 || ''}`,
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    
    GET_TICKETING: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    persona: item.nombrepersona || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },
}

const PersonaAdapters = {


    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nombre: item.nombre || '',
                    apellido1: item.apellido1 || '',
                    apellido2: item.apellido2 || '',
                    nif: item.nif || '',
                    sexo: item.sexo || '',
                    fechanacimiento: item.fechanacimiento || '',
                    estadocivil: item.estadocivil || '',
                    direccion: item.direccion || '',
                    cp: item.cp || '',
                    idpais: item.idpais || '',
                    idprovincia: item.idprovincia || '',
                    telefono1: item.telefono1 || '',
                    extension1: item.extension1 || '',
                    telefono2: item.telefono2 || '',
                    extension2: item.extension2 || '',
                    telefonomovil: item.telefonomovil || '',
                    fax: item.fax || '',
                    email: item.email || '',
                    observaciones: item.observaciones || '',
                    tipocomunicacion: item.tipocomunicacion || [],
                    idpaisnacionalidad: item.idpaisnacionalidad || '',
                    pais_nacionalidad: item.pais_nacionalidad || [],
                    direccionenvio: item.direccionenvio || '',
                    cpenvio: item.cpenvio || '',
                    idpaisenvio: item.idpaisenvio || '',
                    idprovinciaenvio: item.idprovinciaenvio || '',
                    municipioenvio: item.municipioenvio || '',
                    emailenvio: item.emailenvio || '',
                    registroactivo: item.registroactivo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_CREDENCIALES: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    usuario: item.usuario || '',
                    password: item.password || '',
                    es_asociado: item.es_asociado || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_CAMBIAR_CONTRASENIA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    actual_password: item.actual_password || '',
                    new_password: item.new_password || '',
                    new_password_confirmation: item.new_password_confirmation || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    
    PATCH_JUBILACION: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    fechajubilacion: item.fechajubilacion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_REACTIVAR: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // fechajubilacion: item.fechajubilacion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message || '',
                    status: item.status || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // 

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },

    },

    POST_AUTH_CLIENT: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)
                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    userid: item.userid || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

}

const PersonaDatosGeneralesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    nombre: item.nombre || '',
                    apellido1: item.apellido1 || '',
                    apellido2: item.apellido2 || '',
                    nif: item.nif || '',
                    asociados: item.asociados || '',
                    tipoasociado: item.asociados?.[0]?.tipoasociado,
                    numeroasociado: item.asociados?.[0]?.numeroasociado,
                    sexo: item.sexo || '',
                    fechanacimiento: receiveFecha(item.fechanacimiento),
                    fechabaja: receiveFecha(item.fechabaja),
                    fechajubilacion: receiveFecha(item.fechajubilacion),
                    estadocivil: item.estadocivil || '',
                    direccion: item.direccion || '',
                    cp: item.cp || '',
                    idpais: item.idpais || '',
                    idprovincia: item.idprovincia || '',
                    telefono1: item.telefono1 || '',
                    extension1: item.extension1 || '',
                    telefono2: item.telefono2 || '',
                    extension2: item.extension2 || '',
                    telefonomovil: item.telefonomovil || '',
                    fax: item.fax || '',
                    email: item.email || '',
                    observaciones: item.observaciones || '',
                    tipocomunicacion: item.tipocomunicacion || [],
                    idpaisnacionalidad: item.idpaisnacionalidad || '',
                    pais_nacionalidad: item.pais_nacionalidad || [],
                    direccionenvio: item.direccionenvio || '',
                    cpenvio: item.cpenvio || '',
                    idpaisenvio: item.idpaisenvio || '',
                    idprovinciaenvio: item.idprovinciaenvio || '',
                    municipioenvio: item.municipioenvio || '',
                    municipio: item.municipio || '',
                    emailenvio: item.emailenvio || '',
                    registroactivo: item.registroactivo || '',
                    url_fotografia: item.url_fotografia || '',
                    url_firma: item.url_firma || '',
                    url_marca: item.url_marca || '',
                    nombre_colegio_profesional: item.asociados?.[0]?.colegio_profesional?.nombre || '',
                    usuarioweb: item.usuarioweb || ''
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idpersona || '',
                    nombre: item.nombre || '',
                    apellido1: item.apellido1 || '',
                    apellido2: item.apellido2 || '',
                    nif: item.nif || '',
                    sexo: item.sexo || '',
                    fechanacimiento: item.fechanacimiento || '',
                    estadocivil: item.estadocivil || '',
                    direccion: item.direccion || '',
                    cp: item.cp || '',
                    idpais: item.idpais || '',
                    idprovincia: item.idprovincia || '',
                    telefono1: item.telefono1 || '',
                    extension1: item.extension1 || '',
                    telefono2: item.telefono2 || '',
                    extension2: item.extension2 || '',
                    telefonomovil: item.telefonomovil || '',
                    fax: item.fax || '',
                    email: item.email || '',
                    observaciones: item.observaciones || '',
                    tipocomunicacion: item.tipocomunicacion || [],
                    idpaisnacionalidad: item.idpaisnacionalidad || '',
                    pais_nacionalidad: item.pais_nacionalidad || [],
                    direccionenvio: item.direccionenvio || '',
                    cpenvio: item.cpenvio || '',
                    idpaisenvio: item.idpaisenvio || '',
                    idprovinciaenvio: item.idprovinciaenvio || '',
                    municipioenvio: item.municipioenvio || '',
                    municipio: item.municipio || '',
                    emailenvio: item.emailenvio || '',
                    registroactivo: item.registroactivo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idpersona || '',
                    nombre: item.nombre || '',
                    apellido1: item.apellido1 || '',
                    apellido2: item.apellido2 || '',
                    nif: item.nif || '',
                    sexo: item.sexo || '',
                    fechanacimiento: item.fechanacimiento || '',
                    estadocivil: item.estadocivil || '',
                    direccion: item.direccion || '',
                    cp: item.cp || '',
                    idpais: item.idpais || '',
                    idprovincia: item.idprovincia || '',
                    telefono1: item.telefono1 || '',
                    extension1: item.extension1 || '',
                    telefono2: item.telefono2 || '',
                    extension2: item.extension2 || '',
                    telefonomovil: item.telefonomovil || '',
                    fax: item.fax || '',
                    email: item.email || '',
                    observaciones: item.observaciones || '',
                    tipocomunicacion: item.tipocomunicacion || [],
                    idpaisnacionalidad: item.idpaisnacionalidad || '',
                    pais_nacionalidad: item.pais_nacionalidad || [],
                    direccionenvio: item.direccionenvio || '',
                    cpenvio: item.cpenvio || '',
                    idpaisenvio: item.idpaisenvio || '',
                    idprovinciaenvio: item.idprovinciaenvio || '',
                    municipioenvio: item.municipioenvio || '',
                    municipio: item.municipio || '',
                    emailenvio: item.emailenvio || '',
                    registroactivo: item.registroactivo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

    POST_SUBIR_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idpersona || '',
                    imagen: item.imagen || '',
                    tipo: item.tipo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items:any = this.schema(data)

                let formData = new FormData();

                for (const key in items) {
                    formData.append(key, items[key])
                }

                return formData
            }
        },
    },

    
    DELETE_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}

const PersonaDatosColegiacionAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    tipoasociado: item.tipoasociado || '',
                    numeroasociado: item.numeroasociado || '',
                    colegio: item.colegio || '',
                    fechabaja: receiveFechaFormat2(receiveFecha(item.fechabaja)),
                    fechaalta: receiveFechaFormat2(receiveFecha(item.fechaalta)),
                    fechasolicitud: receiveFechaFormat2(receiveFecha(item.fechasolicitud)),
                    motivobaja: item.motivobaja || '',
                    idasociado: item.idasociado || '',
                    observaciones: item.observaciones || '',
                    condicion_pago: item.condicion_pago || '',
                    idcondicionpago: item.condicion_pago?.idcondicionpago || '',
                    servicios_linea: item.servicios_linea || '',
                    nombre_colegio_profesional: item.colegio_profesional?.nombre || '',
                    colegio_profesional: item.colegio_profesional || {},
                    tipocuota: item.tipocuota || '',
                    identidad: item.identidad || '',
                    identidadsede: item.identidadsede || '',
                    motivoexentocuota: item.motivoexentocuota || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tipoasociado: item.tipoasociado || '',
                    fechasolicitud: item.fechasolicitud || '',
                    fechaalta: item.fechaalta || '',
                    numeroasociado: item.numeroasociado || '',
                    idcondicionpago: item.idcondicionpago || '',
                    tipocuota: item.tipocuota || '',
                    // idserviciolinea: item.idserviciolinea || '',
                    observaciones: item.observaciones || '',
                    motivoexentocuota: item.motivoexentocuota || '',
                    // servicioslinea: item.servicioslinea || [],
                    // idserviciolinea: item.tipocuota === 'predefinida' ? (item.idserviciolinea || []) : [],
                    servicioslinea: item.tipocuota === 'predefinida' ? (item.servicioslinea || []) : [],
                    identidad: item.identidad || '',
                    identidadsede: item.identidadsede || '',
                    
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_editar: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idasociado: item.idasociado || '',
                    tipoasociado: item.tipoasociado || '',
                    fechasolicitud: item.fechasolicitud || '',
                    fechaalta: item.fechaalta || '',
                    numeroasociado: item.numeroasociado || '',
                    idcondicionpago: item.idcondicionpago || '',
                    // idserviciolinea: item.tipocuota === 'predefinida' ? (item.idserviciolinea || []) : [],
                    // idserviciolinea: item.idserviciolinea || '',
                    tipocuota: item.tipocuota || '',
                    observaciones: item.observaciones || '',
                    motivoexentocuota: item.motivoexentocuota || '',
                    // servicioslinea: item.servicioslinea || [],
                    servicioslinea: item.tipocuota === 'predefinida' ? (item.servicioslinea || []) : [],
                    identidad: item.identidad || '',
                    identidadsede: item.identidadsede || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    
    PATCH_REACTIVAR: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idasociado: item.idasociado || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}

export {
    PersonasAdapters,
    PersonaAdapters,
    PersonaDatosGeneralesAdapters,
    PersonaDatosColegiacionAdapters,
}